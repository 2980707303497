import logo from './logo.svg';
import './App.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import SignInSide from './Landing';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBW-xOIy4w04xRoIjC9dl9c3MATao_p62c",
  authDomain: "travelcompanion-387109.firebaseapp.com",
  projectId: "travelcompanion-387109",
  storageBucket: "travelcompanion-387109.appspot.com",
  messagingSenderId: "435828620553",
  appId: "1:435828620553:web:4b556321c93e894a02e7d8",
  measurementId: "G-HVFFL7VCXC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const analytics = getAnalytics(app);

console.log(SignInSide);

function App() {
  return (
    <div className="App">
      <SignInSide />
    </div>
  );
}

export default App;

export {
  app,
  db
}
