import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

import { LogoIcon, SitemarkIcon } from './CustomIcons';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: 'Cutting-edge Solutions',
    description:
      'With a deep technical background and leadership expertise, I bring forward-thinking solutions that not only solve today’s challenges but set the stage for future innovation.',
  },
  {
    icon: <ConstructionRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: 'Sustainable Growth',
    description:
      'My leadership style is built to cultivate long-term success, ensuring that every team I lead and project I manage thrives and evolves with the future in mind.',
  },
  {
    icon: <ThumbUpAltRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: 'Empowering Experience',
    description:
      'Whether leading as a manager or hands-on as a developer, I prioritize clear communication, streamlined processes, and a collaborative approach for a seamless and rewarding',
  },
  {
    icon: <AutoFixHighRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: 'Versatile Leadership',
    description:
      'From managing teams to overseeing platforms, I adapt to varied business and technical needs, driving efficiency and fostering innovation.',
  },
];

export default function Content() {
  return (
    <Stack
      sx={{ flexDirection: 'column', alignSelf: 'center', gap: 4, maxWidth: 450 }}
    >
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <LogoIcon width={70} height={100} style={{ margin: 0 }} />
        <Typography variant="h1" sx={{ lineHeight: "100px", fontSize: "2em" }}>putko.pl</Typography>
      </Box>
      {items.map((item, index) => (
        <Stack key={index} direction="row" sx={{ gap: 2 }}>
          {item.icon}
          <div>
            <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {item.description}
            </Typography>
          </div>
        </Stack>
      ))}
    </Stack>
  );
}